//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var platform = require('platform');
export default {
    props: ['widget'],
    data() {
        return {
            step: 1,
            active: 1,
            categories: [],
            phones: [],
            phoneName: 'Puhelimen suojat',
            tablets: [],
            tabletName: 'Tablet suojat',
            watches: [],
            watchName: 'Älykellotarvikkeet',
            subCategory: [],
            expand: false,
            limit: 16,
            tooltip: `
                <div class="space-y-4">
                    <p>Voit tarkistaa laitteesi mallimerkinnän helposti sen asetuksista:</p>
                    <p class="">
                        <span class="font-bold">iPhone:</span><span>Asetukset → Yleiset → Tietoja → Mallin nimi</span>
                    </p>
                    <p>
                        <span class="font-bold">Android:</span><span>Asetukset → Tietoja puhelimesta → Mallinumero / -nimi</span>
                    </p>
                    <p>Mikäli mallimerkintä ei kerro sen tarkkaa mallia, mallinumeron googlaamalla löytyy tunnetumpi mallinimi.</p>
                </div>
                `,
        };
    },
    methods: {
        onBrandClick(step, children) {
            this.step = step;
            this.subCategory = children;
            this.expand = false;
        },
        onCategoryClick(active) {
            this.active = active;
            this.step = 1;
            this.expand = false;
            this.subCategory = [];
        },
    },
    computed: {
        device() {
            const defaultMessage = 'Emme osanneet päätellä laitteesi mallia, klikkaa linkkiä lukemaan miten saat sen selville';
            try {
                return platform.manufacturer && platform.product ? `${platform.manufacturer} ${platform.product}` : defaultMessage;
            } catch (e) {
                return defaultMessage;
            }
        },
        limitedSubCategory() {
            const copy = [...this.subCategory];
            return this.expand ? copy : copy.splice(0, this.limit);
        },
    },
    fetchKey: 'modelselector',
    async fetch() {
        const {
            data: { categories },
        } = await this.$axios.$get('/topmenu');
        this.categories = categories.items[0];
        this.phones = this.categories.children.filter((item) => item.name === this.phoneName)[0] || [];
        this.tablets = this.categories.children.filter((item) => item.name === this.tabletName)[0] || [];
        this.watches = this.categories.children.filter((item) => item.name === this.watchName)[0] || [];
    },
};
